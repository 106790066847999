"use client";

import { FC, PropsWithChildren, useEffect } from "react";

import { Toaster } from "@/components/ui/sonner";
import Script from "next/script";

export const Providers: FC<PropsWithChildren & { nonce: string }> = ({
  children,
  nonce,
}) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const grayscale = window.localStorage.getItem("grayscale");
      if (grayscale) {
        document.body.style.filter = "grayscale(1)";
      }
    }
  }, []);

  return (
    <>
      {children}
      <Toaster
        richColors
        toastOptions={{ unstyled: false }}
        theme="light"
        duration={3000}
      />
      <Script
        type="text/javascript"
        src="https://cdn.weglot.com/weglot.min.js"
        nonce={nonce}
        strategy="afterInteractive"
        onReady={() => {
          (window as any)?.Weglot?.initialize({
            api_key: "wg_0865c2ef9f46203c81505f72ea9d9d7f2",
            hide_switcher: true,
            cache: true,
          });
        }}
      />
    </>
  );
};
